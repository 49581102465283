@import '../../assets/style/variables.css';

.gt-q-picker {
  max-width: 700px;
  min-width: 300px;
  border-bottom: var(--gt-border);
}

.gt-q-picker__category {
  border: var(--gt-border);
  border-bottom: none;

  &:before {
    display: none;
  }
}

.gt-q-picker__questions-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  border-top: var(--gt-border);
}

.gt-q-picker__category-text {
  color: var(--gt-text);
}

button.gt-q-picker__question {
  text-align: left;
  color: var(--gt-blue);
  text-transform: none;
  font-size: 1rem;
}
