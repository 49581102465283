.gt-pure-select {
    padding-top: 0.5em;
    width: 100%;
    display: flex;
    align-items: center;
}

.gt-pure-select__input {
    width: 100%;
}

.gt-pure-select__clear {
    width: 42px;
    height: 42px;
}
